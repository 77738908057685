<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
  >
    <b-card
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="mb-2">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- left -->
            <h5>Кастомные поля</h5>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-primary"
                @click="isAddFieldGroupSidebarOpen = true"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle text-nowrap">Группу</span>
              </b-button>
              <b-button
                variant="primary"
                class="ml-1"
                @click="isAddFieldSidebarOpen = true"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle text-nowrap">Поле</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card
          v-for="(fieldGroup, fieldGroupIndex) in fieldGroupsWithItems"
          :key="'fg' + fieldGroup.id"
          no-body
          class="border"
          :class="{
            'mb-0': fieldGroupIndex === fieldGroupsWithItems.length - 1
          }"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <span class="align-middle ml-50">{{ fieldGroup.name }}</span>
            </b-card-title>
            <b-dropdown
              v-if="fieldGroup.id !== null"
              variant="link"
              no-caret
              class="m-n1"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="showEditFieldGroupSidebar(fieldGroup)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Изменить</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteFieldGroup(fieldGroup)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-table
            ref="refFieldGroupListTable"
            class="position-relative list-view-table mb-0"
            responsive
            :fields="[
              { key: 'id', label: 'ID', sortable: false },
              { key: 'name', label: 'Поле', sortable: false },
              { key: 'value', label: 'Данные', sortable: true },
              { key: 'actions', label: 'Действия' },
            ]"
            :items="fieldGroup.items"
            primary-key="key"
            :sort-by="'sort_order'"
            show-empty
            empty-text="Ничего не найдено!"
            :sort-desc="false"
          >

            <template #cell(name)="data">
              {{ data.item.name }}
              <div class="small text-secondary">
                {{ data.item.key }}
              </div>
            </template>

            <template #cell(value)="data">
              <div v-if="data.item.type === 'boolean' ">
                <b-badge
                  pill
                  variant=""
                  class="text-capitalize"
                  :variant="data.item.value ? 'light-success' : 'light-secondary'"
                >
                  {{ data.item.value ? 'Вкл' : 'Выкл' }}
                </b-badge>
              </div>
              <div
                v-else-if="data.item.type === 'image'"
                class="small"
              >
                <template v-if="data.item.value">
                  <a
                    :href="data.item.value.url"
                    target="_blank"
                    class="a-img-preview"
                  >
                    <img
                      :src="data.item.value.url"
                    >
                  </a>
                </template>
                <template v-else>
                  -
                </template>
              </div>
              <div
                v-else-if="data.item.type === 'images'"
              >
                <div
                  v-if="Array.isArray(data.item.value) && data.item.value.length"
                  class="a-img-previews"
                >
                  <a
                    v-for="file in data.item.value"
                    :key="'ii' + data.item.id + file.name"
                    :href="file.url"
                    target="_blank"
                    class="a-img-preview"
                  >
                    <img
                      :src="file.url"
                      class="a-img-preview"
                    >
                  </a>
                </div>
                <template v-else>
                  -
                </template>
              </div>
              <div
                v-else-if="data.item.type === 'text'"
                class="field-text-value small"
              >
                {{ data.item.value || '-' }}
              </div>
              <div
                v-else-if="data.item.type === 'files'"
                class="small"
              >
                <template v-if="Array.isArray(data.item.value) && data.item.value.length">
                  <b-link
                    v-for="file in data.item.value"
                    :key="'fi' + data.item.id + file.name"
                    :href="file.url"
                    target="_blank"
                    class="d-block"
                  >
                    {{ file.name }}
                  </b-link>
                </template>
                <template v-else>
                  -
                </template>
              </div>
              <div
                v-else-if="data.item.type === 'file'"
                class="small"
              >
                <template
                  v-if="data.item.value"
                >
                  <b-link
                    variant="flat-primary"
                    :href="data.item.value?.url"
                    target="_blank"
                  >
                    {{ data.item.value?.name }}
                  </b-link>
                </template>

                <template v-else>
                  -
                </template>
              </div>
              <div
                v-else-if="data.item.type === 'json'"
                class="small"
              >
                {{ data.item.value || '-' }}
                <div class="text-danger">
                  Данный тип устрарел. Будет преобразован в обычные поля.
                </div>
              </div>
              <div
                v-else
                class="small"
              >
                {{ data.item.value || '-' }}
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <template>
                  <b-dropdown-item
                    v-if="data.item.type !== 'json'"
                    @click="showEditFieldValueSidebar(data.item)"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Изменить значение</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="showEditFieldSidebar(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Изменить поле</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="deleteField(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Удалить</span>
                  </b-dropdown-item>
                </template>

              </b-dropdown>
            </template>
          </b-table>

        </b-card>
      </div>

    </b-card>
    <add-field-group-sidebar
      :is-active.sync="isAddFieldGroupSidebarOpen"
      @add="addFieldGroup"
    />
    <edit-field-group-sidebar
      :is-active.sync="isEditFieldGroupSidebarOpen"
      :group.sync="editableGroupSidebar"
      @update="updateFieldGroup"
    />
    <add-field-sidebar
      :is-active.sync="isAddFieldSidebarOpen"
      :groups="fieldGroups"
      @add="addField"
    />
    <edit-field-sidebar
      :is-active.sync="isEditFieldSidebarOpen"
      :field.sync="editableFields"
      :groups="fieldGroups"
      @update="updateField"
    />
    <edit-field-value-sidebar
      :is-active.sync="isEditFieldValueSidebarOpen"
      :field.sync="editableFields"
      @update="updateFieldValue"
    />
  </b-overlay>
</template>

<script>
import {
  getFieldGroups as getFieldGroupsApi, deleteFieldGroup as deleteFieldGroupApi, createFieldGroup, updateFieldGroup as updateFieldGroupApi,
} from '@/services/main-api/websites/externals/field-groups'
import {
  getFields as getFieldsApi,
  deleteField as deleteFieldApi,
  createField,
  updateField as updateFieldApi,
} from '@/services/main-api/websites/externals/fields'
import {
  ref, onMounted, computed, nextTick,
} from 'vue'
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BOverlay, BLink, BBadge, BAlert, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import useDeleteItem from '@/composables/useDeleieItem'
import cloneDeep from 'lodash/cloneDeep'
import AddFieldGroupSidebar from '../components/AddFieldGroupSidebar.vue'
import EditFieldGroupSidebar from '../components/EditFieldGroupSidebar.vue'
import AddFieldSidebar from '../components/AddFieldSidebar.vue'
import EditFieldSidebar from '../components/EditFieldSidebar.vue'
import EditFieldValueSidebar from '../components/EditFieldValueSidebar.vue'

const defaultGroup = {
  id: null,
  name: 'Без группы',
  description: '',
}

export default {
  name: 'ListCustomFieldView',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BLink,
    BBadge,
    BAlert,
    BCardHeader,
    BCardTitle,
    AddFieldGroupSidebar,
    EditFieldGroupSidebar,
    AddFieldSidebar,
    EditFieldSidebar,
    EditFieldValueSidebar,
  },
  props: {
    siteId: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false)

    const fieldGroups = ref([])

    const getFieldGroups = async () => {
      try {
        const res = await getFieldGroupsApi({
          websiteID: props.siteId,
          orderBy: 'name',
          sortedBy: 'asc',
          perPage: 9999,
        })

        fieldGroups.value = [defaultGroup, ...res.data.data]
      } catch (e) {
        fieldGroups.value = [defaultGroup]
      }
    }

    const fields = ref([])

    const getFields = async () => {
      try {
        const res = await getFieldsApi({
          websiteID: props.siteId,
          orderBy: 'name',
          sortedBy: 'asс',
          perPage: 9999,
        })

        fields.value = res.data.data
      } catch (e) {
        fields.value = []
      }
    }

    const getData = async () => {
      loading.value = true
      await Promise.all([
        getFields(),
        getFieldGroups(),
      ])
      loading.value = false
    }

    onMounted(() => {
      getData()
    })

    const fieldGroupsWithItems = computed(() => fieldGroups.value.map(fieldGroup => ({
      ...fieldGroup,
      items: fields.value.filter(field => field.field_group_id === fieldGroup.id),
    })))

    // BEGIN Group
    const editableGroupSidebar = ref(null)
    const isAddFieldGroupSidebarOpen = ref(false)
    const isEditFieldGroupSidebarOpen = ref(false)

    const showEditFieldGroupSidebar = async item => {
      editableGroupSidebar.value = null
      await nextTick()
      editableGroupSidebar.value = item
      isEditFieldGroupSidebarOpen.value = true
    }

    const addFieldGroup = async newGroup => {
      loading.value = true
      try {
        await createFieldGroup({
          website_id: props.siteId,
          name: newGroup.name,
          description: newGroup.description,
        })

        await getFieldGroups()
      } catch (e) {
        // ...
      } finally {
        loading.value = false
      }
    }

    const updateFieldGroup = async item => {
      const index = fieldGroups.value.findIndex(fieldGroup => fieldGroup.id === item.id)
      if (index !== -1) {
        const newValue = cloneDeep(fieldGroups.value[index])
        newValue.name = item.name
        newValue.description = item.description
        fieldGroups.value.splice(index, 1, newValue)

        await updateFieldGroupApi(newValue)
      }
    }

    const { deleteItem: deleteFieldGroup } = useDeleteItem({
      deleteHandler: item => deleteFieldGroupApi(item.id),
      getQuestionTextBeforeDeletion: item => `Группа <strong>${item.name}</strong> будет удалена`,
      getTextAfterDeletion: () => 'Группа была удалена.',
      runAfterDeletion: async () => {
        await getData()
      },
    })
    // END Group

    // BEGIN Fields
    const editableFields = ref(null)
    const isAddFieldSidebarOpen = ref(false)
    const isEditFieldSidebarOpen = ref(false)
    const isEditFieldValueSidebarOpen = ref(false)

    const showEditFieldSidebar = async item => {
      editableFields.value = null
      await nextTick()
      editableFields.value = item
      isEditFieldSidebarOpen.value = true
    }

    const showEditFieldValueSidebar = async item => {
      editableFields.value = null
      await nextTick()
      editableFields.value = item
      isEditFieldValueSidebarOpen.value = true
    }

    const addField = async newField => {
      loading.value = true
      try {
        await createField({
          website_id: props.siteId,
          name: newField.name,
          description: newField.description,
          type: newField.type,
          key: newField.key,
          value: newField.value,
          sort_order: newField.sort_order,
          field_group_id: newField.field_group_id,
        })

        await getFields()
      } catch (e) {
        // ...
      } finally {
        loading.value = false
      }
    }

    const updateField = async item => {
      const index = fields.value.findIndex(field => field.id === item.id)
      if (index !== -1) {
        const newValue = cloneDeep(fields.value[index])
        newValue.name = item.name
        newValue.description = item.description
        newValue.field_group_id = item.field_group_id
        newValue.sort_order = item.sort_order
        newValue.value = item.value

        fields.value.splice(index, 1, newValue)
        await updateFieldApi(newValue)
      }
    }

    const updateFieldValue = async item => {
      console.log('updateFieldValue', item)

      const index = fields.value.findIndex(field => field.id === item.id)
      if (index !== -1) {
        const newValue = cloneDeep(fields.value[index])
        newValue.value = item.value
        fields.value.splice(index, 1, newValue)

        console.log(newValue)
        await updateFieldApi({
          id: item.id,
          value: item.value,
        })
      }
    }

    const { deleteItem: deleteField } = useDeleteItem({
      deleteHandler: item => deleteFieldApi(item.id),
      getQuestionTextBeforeDeletion: item => `Поле <strong>${item.name}</strong> будет удалено`,
      getTextAfterDeletion: () => 'Поле было удалено.',
      runAfterDeletion: async () => {
        await getData()
      },
    })
    // END Fields

    return {
      loading,
      fieldGroups,

      fields,

      fieldGroupsWithItems,

      isAddFieldGroupSidebarOpen,
      isEditFieldGroupSidebarOpen,
      editableGroupSidebar,
      showEditFieldGroupSidebar,
      addFieldGroup,
      deleteFieldGroup,
      updateFieldGroup,

      editableFields,
      isAddFieldSidebarOpen,
      isEditFieldSidebarOpen,
      isEditFieldValueSidebarOpen,
      showEditFieldSidebar,
      showEditFieldValueSidebar,
      addField,
      deleteField,
      updateField,
      updateFieldValue,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}

.field-text-value{
  white-space: pre-line;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.a-img-preview{
  position: relative;
  width: 50px;
  height: 50px;
  display: block;
  overflow: hidden;
  img{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}
.a-img-previews{
  display: flex;
  gap: 10px;
  .a-img-preview{
    //display: inline-block;
  }
}
</style>
