<template>
  <b-sidebar
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить группу
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <div class="p-2">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <!-- Form -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model.trim="item.description"
                placeholder="Введите описание"
                :state="get(validation, 'description[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'description[0]')"
                class="text-danger"
              >
                {{ get(validation, 'description[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex mt-1">
          <b-button
            variant="primary"
            class="mr-2"
            @click="createItem"
          >
            Добавить
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BRow, BSidebar, BFormCheckbox, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { get } from 'lodash'
import { getCurrentInstance } from 'vue'
import useCrudCreate from '@/composables/useCrudCreate'
import fieldTypes from '@/modules/sites-section/site-external-section/custom-object/composables/fieldTypes'

export default {
  name: 'AddFieldGroupSidebar',
  components: {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BSidebar,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const instance = getCurrentInstance().proxy

    const createEntity = async () => {
      instance.$emit('add', {
        name: instance.item.name,
        description: instance.item.description,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      createItem,
    } = useCrudCreate({
      item: {
        name: '',
        description: '',
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      createEntity,
      getTextAfterCreation: () => 'Группа была создана.',
      runAfterCreation: () => {
        instance.$emit('update:is-active', false)
      },
    })

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      createItem,
      get,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
