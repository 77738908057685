import axiosIns from '@/libs/axios'

export function getFieldGroup({ id, includes }) {
  return axiosIns.get(`/websites/externals/field-groups/${id}`, {
    params: {
      includes,
    },
  })
}

export function getFieldGroups({
  websiteID, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/websites/externals/field-groups', {
    params: {
      websiteID,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createFieldGroup({
  website_id,
  name,
  description,
}) {
  return axiosIns.post('/websites/externals/field-groups', {
    website_id,
    name,
    description,
  })
}

export function updateFieldGroup({
  id,
  name,
  description,
}) {
  return axiosIns.patch(`/websites/externals/field-groups/${id}`, {
    name,
    description,
  })
}

export function deleteFieldGroup(id) {
  return axiosIns.delete(`/websites/externals/field-groups/${id}`)
}

export default {
  getFieldGroup,
  getFieldGroups,
  createFieldGroup,
  updateFieldGroup,
  deleteFieldGroup,
}
