<template>
  <b-sidebar
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Изменить значение
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <div class="p-2">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>

        <b-row>
          <!-- Form -->
          <b-col
            cols="12"
          >
            <b-form-group
              :label="item.name"
              label-for="field"
              :description="item.description"
            >

              <b-form-input
                v-if="item.type === 'string'"
                id="field"
                v-model.trim="item.value"
                placeholder="Введите значение"
                :state="get(validation, 'value[0]') ? false : null"
              />
              <b-form-textarea
                v-else-if="item.type === 'text'"
                id="field"
                v-model.trim="item.value"
                placeholder="Введите значение"
                :state="get(validation, 'value[0]') ? false : null"
              />
              <b-form-checkbox
                v-else-if="item.type === 'boolean'"
                v-model="item.value"
                :state="get(validation, 'value[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.value">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>

              <image-uploader-media
                v-else-if="item.type === 'image'"
                v-model="item.value"
              />
              <images-uploader-media
                v-else-if="item.type === 'images'"
                v-model="item.value"
              />
              <file-uploader-media
                v-else-if="item.type === 'file'"
                v-model="item.value"
              />
              <files-uploader-media
                v-else-if="item.type === 'files'"
                v-model="item.value"
              />

              <small
                v-if="!!get(validation, 'value[0]')"
                class="text-danger"
              >
                {{ get(validation, 'value[0]') }}
              </small>
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Form Actions -->
        <div class="d-flex mt-1">
          <b-button
            variant="primary"
            class="mr-2"
            @click="updateItem"
          >
            Сохранить
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </div>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BRow, BSidebar, BFormCheckbox, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import get from 'lodash/get'
import { getCurrentInstance, watch } from 'vue'
import useCrudEdit from '@/composables/useCrudEdit'

import imageUploaderMedia from '@/components/ImageUploaderMedia.vue'
import imagesUploaderMedia from '@/components/ImagesUploaderMedia.vue'
import fileUploaderMedia from '@/components/FileUploaderMedia.vue'
import filesUploaderMedia from '@/components/FilesUploaderMedia.vue'
import fieldTypes from '../composables/fieldTypes'

export default {
  name: 'EditFieldValueSidebar',
  components: {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BSidebar,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    vSelect,
    imageUploaderMedia,
    imagesUploaderMedia,
    fileUploaderMedia,
    filesUploaderMedia,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy

    const updateEntity = async () => {
      instance.$emit('update', {
        id: instance.item.id,
        name: instance.item.name,
        description: instance.item.description,
        value: instance.item.value,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        name: '',
        description: '',
        value: null,
        type: fieldTypes[0].value,
      },
      validation: {
      },
      updateEntity,
      getTextAfterUpdate: () => 'Значение было изменено.',
      runAfterUpdate: () => {
        instance.$emit('update:is-active', false)
        instance.$emit('completed')
      },
    })

    watch(() => props.field, field => {
      if (field) {
        instance.item.id = field.id
        instance.item.name = field.name
        instance.item.description = field.description
        instance.item.value = field.value
        instance.item.type = field.type
      }
    })

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
      get,
      fieldTypes,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
