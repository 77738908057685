const types = [
  {
    name: 'Строка',
    value: 'string',
  },
  {
    name: 'Текст',
    value: 'text',
  },
  {
    name: 'Логический',
    value: 'boolean',
  },
  {
    name: 'Файл',
    value: 'file',
  },
  {
    name: 'Файлы',
    value: 'files',
  },
  {
    name: 'Изображение',
    value: 'image',
  },
  {
    name: 'Изображения',
    value: 'images',
  },
]
export default types
