<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных сайта
      </h4>
      <div class="alert-body">
        Не найден сайт с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'sites'}"
        >
          Список сайтов
        </b-link>
        и выбрать другой.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <app-breadcrumb
      :breadcrumb="breadcrumb"
      :page-title="pageTitle"
    />
    <b-card>
      <b-row>
        <b-col>
          <b-table-simple
            :striped="true"
            :bordered="false"
            :borderless="true"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="mt-1"
          >
            <b-tbody>
              <b-tr>
                <b-th>
                  ID
                </b-th>
                <b-td>
                  {{ item.id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Ключ
                </b-th>
                <b-td>
                  {{ item.key }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Тип
                </b-th>
                <b-td>
                  Внешний
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Режим работы
                </b-th>
                <b-td>
                  {{ get(siteModes.find(t => t.value === item.mode), 'name') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Описание
                </b-th>
                <b-td>
                  {{ item.description }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div class="d-flex flex-wrap">
            <b-button
              v-if="item.id"
              :to="{ name: 'sites.external.edit', params: { id: item.id } }"
              variant="primary"
            >
              Изменить
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteItem(item)"
            >
              Удалить
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!--    <b-card>
      <b-row>
        <b-col
          cols="6"
          md="3"
          lg="4"
        >
          <b-button
            variant="outline-primary"
            @click="$router.push({name: 'portfolios', params: { siteId: item.id }})"
          >
            <b-form-row class="align-items-center">
              <b-col cols="auto">
                <feather-icon
                  icon="FolderIcon"
                  class="mr-50"
                />
              </b-col>
              <b-col cols="auto">
                Портфолио
              </b-col>
              <b-col cols="auto">
                <b-badge variant="primary">
                  {{ countPortfolio }}
                </b-badge>
              </b-col>
            </b-form-row>

          </b-button>
        </b-col>
      </b-row>
    </b-card>-->

    <custom-models-list
      v-if="item.id"
      :site-id="item.id"
    />

    <custom-fields-list
      v-if="item.id"
      :site-id="item.id"
    />

  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BAlert, BLink, BBadge, BTabs, BTab,
  BFormGroup, BFormInput, BFormTextarea, BCardHeader, BCardTitle, BTable, BFormCheckbox,
  BTableSimple, BTbody, BTr, BTd, BTh, BListGroup, BListGroupItem, BFormRow,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getCurrentInstance, ref, computed } from 'vue'
import siteModes from '@/modules/sites-section/sites/composables/siteModes'
import siteTypes from '@/modules/sites-section/sites/composables/siteTypes'

import {
  deleteWebsite as deleteWebsiteApi,
  getWebsite as getWebsiteApi,
} from '@/services/main-api/websites/externals/websites'
import useCrudShow from '@/composables/useCrudShow'
import CustomModelsList from '@/modules/sites-section/site-external-section/custom-models/views/ListView.vue'
import CustomFieldsList from '@/modules/sites-section/site-external-section/custom-fields/views/ListView.vue'
import appBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'

export default {
  name: 'ShowItemView',
  components: {
    CustomModelsList,
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTh,
    BListGroup,
    BListGroupItem,
    BFormRow,
    appBreadcrumb,
    CustomFieldsList,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const getItem = async () => {
      try {
        const res = await getWebsiteApi({ id: props.id })
        instance.item = res.data.data
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    // BEGIN Портфолио
    const countPortfolio = ref(0)

    // const getPortfoliosCount = async () => {
    //   try {
    //     const res = await getPortfoliosApi({
    //       site: props.id,
    //       perPage: 1000,
    //     })
    //     countPortfolio.value = res.data.data.length
    //   } catch (e) {
    //     countPortfolio.value = 0
    //   }
    // }
    // END Портфолио

    const {
      item,
      loading,
      deleteItem,
    } = useCrudShow({
      item: {
        id: null,
        name: '',
        description: '',
        key: '',
        mode: siteModes[0].value,
      },
      getMoreData: async () => {
        // await getPortfoliosCount()
      },
      getItem,
      deleteEntity: i => deleteWebsiteApi(i.id),
      getQuestionTextBeforeDeletion: i => `Сайт <strong>${i.name}</strong> будет удален`,
      getTextAfterDeletion: () => 'Сайт был удален.',
      runAfterDeletion: () => {
        instance.$router.push({ name: 'sites' })
      },
    })

    // BEGIN Breadcrumb
    const breadcrumb = computed(() => [
      {
        text: 'Сайты',
        to: { name: 'sites' },
      },
      {
        text: get(item.value, 'name', 'Сайт'),
        active: true,
      },
    ])
    const pageTitle = computed(() => get(item.value, 'name', 'Сайт'))
    // END Breadcrumb

    return {
      item,
      loading,

      getItemError,
      deleteItem,

      siteTypes,
      siteModes,

      countPortfolio,

      breadcrumb,
      pageTitle,

      get,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
